<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="8" xs="12" sm="12" md="8" lg="8" xl="8">
                    <v-row>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productcolor.prod_co_nome" :rules="rules.nomeRules" label="Nome da Cor" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productcolor.prod_co_hexa" label="Hexadecimal" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productcolor.prod_co_uint" label="Uint" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productcolor.prod_co_cod" label="Código" outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productcolor.prod_co_tipo" label="Tipo" outlined></v-text-field>
                        </v-col>

                        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-switch color="lime" v-model="productcolor.prod_co_status" label="Status"></v-switch>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" color="success" @click="onSubmit">
                                <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4" xs="12" sm="12" md="4" lg="4" xl="4">

                    <v-layout row wrap justify-center column pt-3>
                        <v-flex v-if="preview || productcolor.prod_co_img">
                            <v-card>
                                <v-img v-if="preview" :src="preview" aspect-ratio="2"></v-img>
                                <v-img v-if="productcolor.prod_co_img && !preview" :src="`${base_url}upload/product_color/${productcolor.prod_co_img}`" aspect-ratio="2"></v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center>
                            <input type="file" ref="file" style="display:none;" id="file" @change="onFileChange" />
                            <v-btn v-if="!preview && !productcolor.prod_co_img" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                                Adicionar Imagem
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                </v-col>

            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import {
    mapState
} from 'vuex';

export default {
    created() {
        
    },
    props: {
        productcolor: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_co_id: '',
                    prod_co_nome: '',
                    prod_co_hexa: '',
                    prod_co_uint: '',
                    prod_co_cod: '',
                    prod_co_img: '',
                    prod_co_tipo: '',
                    prod_co_status: false,
                }
            },
        },
        prod_co_id: '',
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormProductColorComponent",
    data: () => ({
        valid: true,
        preview: null,
        base_url: URL_BASE,
        rules: {
            nomeRules: [
                v => !!v || 'Campo Nome é obrigatório',
                v => (v && v.length <= 200) || 'Nome precisa ter até 200 Caracteres'
            ]
        },
    }),
    methods: {

        onSubmit() {

            const action = this.update ? 'updateProductColor' : 'storeProductColor';
            const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

            const formData = new FormData();

            if (this.upload !== null)
                formData.append('prod_co_img', this.upload);

            formData.append('prod_co_id', this.productcolor.prod_co_id);
            formData.append('prod_co_nome', this.productcolor.prod_co_nome);
            formData.append('prod_co_hexa', this.productcolor.prod_co_hexa);
            formData.append('prod_co_uint', this.productcolor.prod_co_uint);
            formData.append('prod_co_cod', this.productcolor.prod_co_cod);
            formData.append('prod_co_tipo', this.productcolor.prod_co_tipo);
            formData.append('prod_co_status', this.productcolor.prod_co_status);

            this.$store.dispatch(action, formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: msg,
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$router.push({
                        name: 'product.colors'
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
